<template>
  <section class="list-select-mtr">
    <div class="mb-4 list" v-if="receivedMtrs.length > 0">
      <v-layout
        row
        v-for="(group, index) in groupedMtrs"
        :key="index"
        align-center
        wrap
      >
        <v-flex xs12>
          <v-layout
            class="mb-2"
            row
            align-center
          >
            <strong class="grey--text mr-4">{{ capitalize(index) }}</strong>

            <v-checkbox
              v-model="monthsCheckBoxes[index]"
              class="select-item green--text shrink"
              color="red"
              off-icon="mdi-check-all primary--text"
              on-icon="mdi-close"
              @change="toggleAllMonthMtrs($event, index)"
            >
              <small
                slot="label"
                :class="monthsCheckBoxes[index] ? 'red--text' : 'primary--text'"
              >
                {{ monthsCheckBoxes[index] ? 'Desmarcar todos' : 'Selecionar todos' }}
              </small>
            </v-checkbox>
          </v-layout>
        </v-flex>

        <v-flex xs12>
          <v-card
            class="mb-3 item"
            v-for="mtr in group"
            :key="mtr.id"
          >
            <v-card-text>
              <v-layout row align-center>
                <v-checkbox
                  class="select-item"
                  color="primary"
                  v-model="mtr.checked"
                  off-icon="mdi-radiobox-blank"
                  on-icon="mdi-check-circle"
                  @change="toggleMtr(mtr, mtr.checked, index)"
                >
                  <div slot="label">
                    Nº {{mtr.number}}
                  </div>
                </v-checkbox>

                <v-spacer />

                <span class="grey--text">
                  Recebido em: {{ format(mtr.received_at, 'DD/MM/YYYY') }}
                </span>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </div>
  </section>
</template>

<script>
import format from 'date-fns/format';
import { isEmpty, pullAt, findIndex, capitalize, orderBy, groupBy, every, forEach } from 'lodash';
import * as ptLocale from 'date-fns/locale/pt';

export default {
  name: 'list-select-mtr',
  data() {
    return {
      capitalize,
      format,
      groupedMtrs: [],
      isEmpty,
      monthsCheckBoxes: {},
      mtrs: [],
    };
  },
  props: {
    receivedMtrs: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    const receivedMtrs = this.receivedMtrs.map(mtr => ({ ...mtr, checked: false }));
    const sortedMtrs = orderBy(receivedMtrs, item => item.received_at, ['desc']);
    this.groupByTodayAndMonth(sortedMtrs);
  },
  methods: {
    toggleMtr(mtr, checked, month) {
      if (checked) {
        this.mtrs.push(mtr);
        if (every(this.groupedMtrs[month], ['checked', true])) {
          this.toggleMonth(month, true);
        }
      } else {
        this.toggleMonth(month, false);
        this.removeMtr(mtr);
      }
      this.$emit('selectMtr', this.mtrs);
    },
    toggleAllMonthMtrs(checked, group) {
      this.groupedMtrs[group] = this.groupedMtrs[group].map((mtr) => {
        if (checked && !mtr.checked) {
          this.mtrs.push(mtr);
        } else if (!checked) {
          this.removeMtr(mtr);
        }
        return { ...mtr, checked };
      });
      this.$emit('selectMtr', this.mtrs);
    },
    removeMtr(mtr) {
      const indexToRemove = findIndex(this.mtrs, ['id', mtr.id]);
      const mtrsCopy = [...this.mtrs];
      pullAt(mtrsCopy, indexToRemove);
      this.mtrs = [...mtrsCopy];
    },
    toggleMonth(month, checked) {
      const modifiedMonths = {};
      modifiedMonths[month] = checked;
      this.monthsCheckBoxes = { ...this.monthsCheckBoxes, ...modifiedMonths };
    },
    formatMonthYear(date) {
      return format(date, 'MMMM [de] YYYY', { locale: ptLocale });
    },
    groupByMonth(array) {
      return groupBy(array, item => this.formatMonthYear(item.received_at));
    },
    groupByTodayAndMonth(array) {
      let hoje = array.filter(item => format(item.received_at, 'DD/MM/YYYY') === format(new Date(), 'DD/MM/YYYY'));
      hoje = !isEmpty(hoje) ? { hoje } : [];
      let otherDays = array.filter(item => format(item.received_at, 'DD/MM/YYYY') !== format(new Date(), 'DD/MM/YYYY'));
      otherDays = this.groupByMonth(otherDays);
      this.groupedMtrs = { ...hoje, ...otherDays };
      this.createMonthsCheckBoxes(this.groupedMtrs);
    },
    createMonthsCheckBoxes(mtrs) {
      forEach(mtrs, (value, key) => {
        this.monthsCheckBoxes[key] = false;
      });
    },
  },
};
</script>

<style lang="scss">
.list-select-mtr {
  >.list {
    .select-item {
      &,
      .v-input__slot {
        margin: 0;
      }

      .v-messages {
        display: none;
      }
    }
  }
}
</style>

