var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-layout",
    { attrs: { row: "", "justify-space-between": "" } },
    [
      _c(
        "v-flex",
        { attrs: { xs5: "" } },
        [
          _c("v-autocomplete", {
            attrs: {
              value: _vm.generator,
              items: _vm.people,
              "item-text": "name",
              clearable: "",
              "hide-no-data": "",
              "no-filter": "",
              placeholder: "Buscar por nome, razão social, CPF ou CNPJ",
              "prepend-inner-icon": "mdi-comment-search-outline",
              label: "GERADOR",
              "return-object": "",
              "persistent-hint": "",
              "menu-props": _vm.autocompleteMenuProps,
              loading: _vm.isFetching,
              "search-input": _vm.search,
              hint: _vm.searchFeedback,
            },
            on: {
              "update:searchInput": function ($event) {
                _vm.search = $event
              },
              "update:search-input": function ($event) {
                _vm.search = $event
              },
              input: _vm.selectPerson,
              "click:clear": _vm.removePerson,
            },
          }),
        ],
        1
      ),
      _c(
        "v-flex",
        { attrs: { xs6: "" } },
        [
          _c(
            "v-layout",
            { attrs: { "justify-space-between": "", row: "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs5: "" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      label: "RESPONSÁVEL PELA EMISSÃO",
                      placeholder: "Nome do responsável",
                      disabled: "",
                    },
                    model: {
                      value: _vm.cdfEmittedBy,
                      callback: function ($$v) {
                        _vm.cdfEmittedBy = $$v
                      },
                      expression: "cdfEmittedBy",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs5: "" } },
                [
                  _c("v-text-field", {
                    attrs: { label: "Cargo", disabled: "" },
                    model: {
                      value: _vm.cdfEmittedByRole,
                      callback: function ($$v) {
                        _vm.cdfEmittedByRole = $$v
                      },
                      expression: "cdfEmittedByRole",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }