var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "in-layout",
    [
      _c(
        "v-container",
        { attrs: { slot: "content", column: "" }, slot: "content" },
        [
          _c("generate-cdf-info", {
            on: { selectGenerator: _vm.setGenerator },
          }),
        ],
        1
      ),
      _c(
        "v-container",
        { attrs: { slot: "list" }, slot: "list" },
        [
          !_vm.isEmpty(_vm.generator)
            ? [
                _vm.receivedMtrs.length > 0
                  ? _c("div", { staticClass: "mb-2 header" }, [
                      _c("h2", [
                        _vm._v(
                          _vm._s(_vm.receivedMtrs.length) +
                            " " +
                            _vm._s(_vm.mtrMessage)
                        ),
                      ]),
                      _c("p", { staticClass: "grey--text" }, [
                        _vm._v(
                          "Selecione os MTR’s e clique no botão emitir para finalizar."
                        ),
                      ]),
                    ])
                  : _c("div", { staticClass: "mb-2 header" }, [
                      _c("h2", [
                        _vm._v("Sem MTR’s recebidos aptos a emitir CDF"),
                      ]),
                    ]),
              ]
            : _vm._e(),
          !_vm.isEmpty(_vm.receivedMtrs)
            ? _c("list-select-mtr", {
                attrs: { receivedMtrs: _vm.receivedMtrs },
                on: { selectMtr: _vm.setMtrs },
              })
            : _vm._e(),
          _c("v-divider", { staticClass: "my-2" }),
          !_vm.isEmpty(_vm.receivedMtrs)
            ? _c(
                "v-layout",
                { attrs: { "justify-center": "" } },
                [
                  _c(
                    "v-btn",
                    { attrs: { flat: "" }, on: { click: _vm.cancel } },
                    [_vm._v("\n        Cancelar\n      ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "success", disabled: !_vm.canEmit() },
                      on: { click: _vm.emitCdf },
                    },
                    [_vm._v("\n        Emitir\n      ")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-layout",
            { attrs: { "justify-center": "" } },
            [
              _vm.isEmpty(_vm.generator)
                ? _c(
                    "empty-content",
                    { staticClass: "mt-5 empty-content" },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "mb-2",
                          attrs: { slot: "content", size: "56" },
                          slot: "content",
                        },
                        [_vm._v("mdi-inbox")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "headline grey--text text--darken-1",
                          attrs: { slot: "message" },
                          slot: "message",
                        },
                        [
                          _vm._v(
                            "\n          Necessário selecionar gerador\n        "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }