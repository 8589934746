<template>
  <in-layout>
    <v-container column slot="content">
      <generate-cdf-info @selectGenerator="setGenerator" />
    </v-container>

    <v-container slot="list">
      <template v-if="!isEmpty(generator)">
        <div class="mb-2 header" v-if="receivedMtrs.length > 0">
          <h2>{{ receivedMtrs.length }} {{ mtrMessage }}</h2>
          <p class="grey--text">Selecione os MTR’s e clique no botão emitir para finalizar.</p>
        </div>
        <div class="mb-2 header" v-else>
          <h2>Sem MTR’s recebidos aptos a emitir CDF</h2>
        </div>
      </template>

      <list-select-mtr
        v-if="!isEmpty(receivedMtrs)"
        @selectMtr="setMtrs"
        :receivedMtrs="receivedMtrs"
      />

      <v-divider class="my-2" />

      <v-layout justify-center v-if="!isEmpty(receivedMtrs)">
        <v-btn flat @click="cancel">
          Cancelar
        </v-btn>

        <v-btn color="success" @click="emitCdf" :disabled="!canEmit()">
          Emitir
        </v-btn>
      </v-layout>

      <v-layout justify-center>
        <empty-content class="mt-5 empty-content" v-if="isEmpty(generator)">
          <v-icon class="mb-2" size="56" slot="content">mdi-inbox</v-icon>
          <div class="headline grey--text text--darken-1" slot="message">
            Necessário selecionar gerador
          </div>
        </empty-content>
      </v-layout>

    </v-container>
  </in-layout>
</template>

<script>
import InLayout from '@/layouts/InLayout.vue';
import GenerateCdfInfo from '@/components/cdf/GenerateCdfInfo.vue';
import ListSelectMtr from '@/components/cdf/ListSelectMtr.vue';
import EmptyContent from '@/components/base/EmptyContent.vue';
import { isEmpty, forEach } from 'lodash';
import { mapGetters } from 'vuex';

export default {
  name: 'genarate-cdf',
  components: {
    InLayout,
    GenerateCdfInfo,
    ListSelectMtr,
    EmptyContent,
  },
  data() {
    return {
      destinator: {},
      generator: {},
      groupedMtrs: [],
      isEmpty,
      mtrs: [],
      residues: [],
      responsible: '',
      responsibleRole: '',
    };
  },
  mounted() {
    this.responsible = this.currentUser.user.name;
    [this.responsibleRole] = this.currentUser.roles;
  },
  methods: {
    setGenerator(generator) {
      this.generator = generator;
      if (isEmpty(generator)) {
        this.mtrs = {};
      }
    },
    setMtrs(mtrs) {
      this.mtrs = mtrs.map(mtr => mtr.id);
      this.setResidues(mtrs);
    },
    setResidues(mtrs) {
      this.residues = [];
      forEach(mtrs, (mtr) => {
        forEach(mtr.residues, (residue) => {
          this.residues.push(residue);
        });
      });
    },
    emitCdf() {
      const body = {
        mtrIds: this.mtrs,
        responsible: this.responsible,
        responsibleRole: this.responsibleRole,
        generatorId: this.generator.id,
        receiverId: this.user.person.id,
      };
      this.$store.dispatch('cdf/createCdf', body)
        .then(() => {
          this.$router.push('/destinador');
          this.$store.dispatch('application/toggleSnackbar', {
            show: true,
            timeout: 6000,
            color: 'success',
            message: 'Certificado de destinação final emitido com sucesso!',
            messageIcon: 'mdi-check',
          });
        })
        .catch((err) => {
          this.$store.dispatch('application/toggleSnackbar', {
            show: true,
            timeout: 6000,
            color: 'error',
            message: err.message,
            messageIcon: 'mdi-alert-outline',
          });
        });
    },
    canEmit() {
      return !isEmpty(this.mtrs)
        && !isEmpty(this.residues)
        && !isEmpty(this.generator)
        && this.responsible.length > 0
        && this.responsibleRole.length > 0;
    },
    cancel() {
      this.$store.dispatch('mtr/emptyReceivedMtrs');
      this.$router.push('/destinador');
    },
  },
  computed: {
    ...mapGetters({
      receivedMtrs: 'mtr/receivedMtrs',
      user: 'auth/currentUser',
    }),
    currentUser() {
      const user = this.$store.getters['auth/currentUser'];
      return user;
    },
    mtrMessage() {
      if (this.receivedMtrs.length === 1) {
        return 'MTR recebido apto a emitir CDF';
      }
      return 'MTR’s recebidos aptos a emitir CDF';
    },
  },
  beforeDestroy() {
    this.$store.dispatch('mtr/emptyReceivedMtrs');
  },
};
</script>

<style>
 .empty-content {
   display: flex;
   flex-direction: column;
 }
</style>
