<template>
  <v-layout row justify-space-between>
    <v-flex xs5>
      <v-autocomplete
        :value="generator"
        :items="people"
        item-text="name"
        clearable
        hide-no-data
        no-filter
        placeholder="Buscar por nome, razão social, CPF ou CNPJ"
        prepend-inner-icon="mdi-comment-search-outline"
        label="GERADOR"
        return-object
        persistent-hint
        :menu-props="autocompleteMenuProps"
        :loading="isFetching"
        :search-input.sync="search"
        :hint="searchFeedback"
        @input="selectPerson"
        @click:clear="removePerson"
      />
    </v-flex>
    <v-flex xs6>
      <v-layout justify-space-between row>
        <v-flex xs5>
          <v-text-field
            v-model="cdfEmittedBy"
            label="RESPONSÁVEL PELA EMISSÃO"
            placeholder="Nome do responsável"
            disabled
          />
        </v-flex>
        <v-flex xs5>
          <v-text-field
            v-model="cdfEmittedByRole"
            label="Cargo"
            disabled
          />
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
import masks from '@/utils/masks';
import validationRules from '@/mixins/validation-rules';
import personService from '@/services/person-service';
import { isEmpty, orderBy } from 'lodash';

export default {
  name: 'generate-cdf-info',
  data() {
    return {
      generator: '',
      cdfEmittedBy: '',
      cdfEmittedByRole: '',
      masks,
      validationRules,
      searchFeedback: '',
      people: [],
      isFetching: false,
      isLoading: false,
      search: null,
      isEmpty,
      autocompleteMenuProps: {
        minWidth: '38%',
        maxWidth: '38%',
        contentClass: 'text-uppercase',
      },
    };
  },
  mounted() {
    this.cdfEmittedBy = this.currentUser.user.name;
    [this.cdfEmittedByRole] = this.currentUser.roles;
    this.search = '';
  },
  watch: {
    search() {
      clearInterval(this.interval);
      this.interval = setTimeout(() => {
        this.searchGenerator();
      }, 500);
    },
    generator(val) {
      if (val.name) {
        val.name = val.name.toUpperCase();
      }
    },
  },
  computed: {
    currentUser() {
      const user = this.$store.getters['auth/currentUser'];
      return user;
    },
  },
  methods: {
    searchGenerator() {
      if (this.generator && this.search === this.generator.name) return;

      if (this.search === null || this.search === undefined || this.search.length < 1) {
        this.removePerson();
      }

      this.isFetching = true;
      this.searchFeedback = '';

      personService.searchGenerator({ q: this.search })
        .then(({ data }) => {
          if (isEmpty(data)) {
            this.searchFeedback = 'Gerador não encontrado.';
            this.$store.dispatch('mtr/emptyReceivedMtrs');
          }
          this.people = orderBy(data, ['name'], ['asc']);
        })
        .catch((err) => {
          this.$store.dispatch('application/toggleSnackbar', {
            show: true,
            timeout: 6000,
            color: 'error',
            message: err.message,
            messageIcon: 'mdi-alert-outline',
          });
        })
        .finally(() => {
          this.isFetching = false;
        });
    },
    selectPerson(generator) {
      if (generator === undefined) return;
      this.$store.dispatch('mtr/emptyReceivedMtrs');
      this.generator = generator;
      this.fetchGeneratorReceivedMtrs(generator.id);
      this.$emit('selectGenerator', generator);
    },
    fetchGeneratorReceivedMtrs(generatorId) {
      this.$store.dispatch('mtr/fetchGeneratorReceivedMtrs', {generatorId, receiverId: this.currentUser.person.id});
    },
    removePerson() {
      this.generator = {};
      this.$store.dispatch('mtr/emptyReceivedMtrs');
      this.$emit('selectGenerator', {});
    },
  },
};
</script>
